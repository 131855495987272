import { Component, OnInit } from '@angular/core';
import { ParticlesConfig } from '../../models/particles-config';

declare let particlesJS: any;

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.invokeParticles();
  }

  scrollToHome(): void {
    document.getElementById('home')?.scrollIntoView({ behavior: 'smooth' });
  }

  public invokeParticles(): void {
    particlesJS('particles-js', ParticlesConfig, () => {});
  }

}
