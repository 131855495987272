import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CarouselComponent } from 'src/app/components/carousel/carousel.component';
interface IAlbum {
  src: string;
  caption: string;
  thumb: string;
}
@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.scss']
})
export class PhotoGalleryComponent implements OnInit {

  album: IAlbum[] = [
    { src: 'assets/img/gallery/original/1.jpg', caption: '',  thumb: 'assets/img/gallery/thumbnails/1_tn.jpg'},
    { src: 'assets/img/gallery/original/2.jpeg', caption: '',  thumb: 'assets/img/gallery/thumbnails/2_tn.jpg'},
    { src: 'assets/img/gallery/original/3.jpeg', caption: '',  thumb: 'assets/img/gallery/thumbnails/3_tn.jpg'},
    { src: 'assets/img/gallery/original/4.jpg', caption: '',  thumb: 'assets/img/gallery/thumbnails/4_tn.jpg'},
    { src: 'assets/img/gallery/original/5.jpeg', caption: '',  thumb: 'assets/img/gallery/thumbnails/5_tn.jpg'},
    { src: 'assets/img/gallery/original/6.jpeg', caption: '',  thumb: 'assets/img/gallery/thumbnails/6_tn.jpg'},
    { src: 'assets/img/gallery/original/7.jpeg', caption: '',  thumb: 'assets/img/gallery/thumbnails/7_tn.jpg'},
    { src: 'assets/img/gallery/original/8.jpeg', caption: '',  thumb: 'assets/img/gallery/thumbnails/8_tn.jpg'},
    { src: 'assets/img/gallery/original/9.jpg', caption: '',  thumb: 'assets/img/gallery/thumbnails/9_tn.jpg'},
    { src: 'assets/img/gallery/original/10.jpg', caption: '',  thumb: 'assets/img/gallery/thumbnails/10_tn.jpg'},
    { src: 'assets/img/gallery/original/11.jpeg', caption: '',  thumb: 'assets/img/gallery/thumbnails/11_tn.jpg'},
    // { src: 'assets/img/gallery/original/12.jpg', caption: '',  thumb: 'assets/img/gallery/thumbnails/12_tn.jpg'},
    { src: 'assets/img/gallery/original/13.jpeg', caption: '',  thumb: 'assets/img/gallery/thumbnails/13_tn.jpg'},
    { src: 'assets/img/gallery/original/14.jpeg', caption: '',  thumb: 'assets/img/gallery/thumbnails/14_tn.jpg'},
    { src: 'assets/img/gallery/original/15.jpeg', caption: '',  thumb: 'assets/img/gallery/thumbnails/15_tn.jpg'},
    { src: 'assets/img/gallery/original/16.jpg', caption: '',  thumb: 'assets/img/gallery/thumbnails/16_tn.jpg'},
    { src: 'assets/img/gallery/original/17.jpg', caption: '',  thumb: 'assets/img/gallery/thumbnails/17_tn.jpg'}
  ];

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {
  }

  open(i: number) {
    this.dialog.open(CarouselComponent, {
      data: this.album[i].src
    });
  }

}
