<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'">
    <mat-toolbar>Menu</mat-toolbar>
    <mat-nav-list>
      <a *ngFor="let item of items" mat-list-item (click)="scrollTo(item.anchor)">{{item.label}}</a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <mat-toolbar [class.is-homepage]="transparent" class="navigation-bar" [ngClass]="show ? 'mat-elevation-z6' : 'transparent'">
      <div class="container flexbox">
        <mat-icon class="logo" (click)="scrollTo('welcome')">terrain</mat-icon>
        <!-- <img src="/assets/img/logo.png" width="100px" height="56.25%" alt="Mateusz Guzik | Geodezja"> -->
        <span class="brand">&nbsp;Mateusz Guzik | Geodezja</span>
        <span class="spacer"></span>
        <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" *ngIf="(isHandset$ | async)">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <div *ngIf="!(isHandset$ | async)">
          <button *ngFor="let item of items" mat-flat-button (click)="scrollTo(item.anchor)">{{item.label}}</button>
        </div>
      </div>
    </mat-toolbar>

    <div class="content">
      <main>
        <ng-content></ng-content>
      </main>
      <app-footer></app-footer>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
