<div id="experience" class="dark-background">
  <div class="container">
    <h1>Zakres działań</h1>
    <div class="row">
      <div class="col">
        <h2>Posiadam uprawnienia geodezyjne zakres 1</h2>
        <p>
          Geodezyjne pomiary sytuacyjno-wysokościowe realizacyjne i inwentaryzacyjne: <br>
        </p>
        <ul>
          <li>Tyczenie obiektów budowlanych (np. Tyczenie budynków, tyczenie sieci uzbrojenia terenu (np. woda, prąd, gaz itp.)</li>
          <li>Sporządzanie Map do celów projektowych</li>
          <li>Pomiary inwentaryzacyjne: czyli jak już coś wybudują trzeba to nanieść na mapy inwentaryzujemy wszystko co wcześniej było tyczone a nawet więcej</li>
        </ul>
      </div>
      <div class="col">
        <h2>Posiadam uprawnienia geodezyjne zakres 2</h2>
        <p>Eozgraniczanie i podziały nieruchomości (gruntów) oraz sporządzanie dokumentacji do celów prawnych.</p>
        <ul>
          <li>Podział działki</li>
          <li>Ustalenie granic ( gdy nie ma wystarczających dokumentów na potwierdzenie przebiegu granic wykonuje się „ustalenie granic”)</li>
          <li>wznowienie granic ( gdy w terenie nie ma punktów granicznych, a są dokumenty potwierdzające przebieg granic wykonuje się „ wznowienie granic”</li>
          <li>rozgraniczenie ( podobne do ustalenia granic lecz rozgraniczenie wykonuje się gdy jest spór o granice między sąsiadami)</li>
          <li>
            mapy do celów prawnych np.:
            <ul>
              <li>mapy gruntów objętych wnioskiem w sprawie stwierdzenia nabycia prawa własności na skutek zasiedzenia, zwa-ne dalej „mapami do zasiedzenia”</li>
              <li>mapy do ustalenia służebności gruntowych</li>
              <li>inne opracowania geodezyjne do celów prawnych</li>
            </ul>
          </li>
          <li>
            badanie stanu prawnego nieruchomości
            <ul>
              <li>ustaleniu czy nieruchomość posiada założoną księgę wieczystą (kw), zbiór dokumentów (ZD) lub dawną księgę hipoteczną (KH)</li>
              <li>	uzyskaniu aktualnego wypisu z księgi wieczystej lub sporządzenia protokołu badania księgi wieczystej</li>
              <li>
                •	w przypadku braku w/w dokumentów na ustaleniu, czy istnieją inne dokumenty określające prawo własności np: akty nadania, akty własności ziemi, akty notarialne kupna-sprzedaży, prawomocne postanowienia sądowe, decyzje administracyjne itp</li>
            </ul>
          </li>
          <li>sporządzanie wykazów synchronizacyjnych ( dane w Ewidencji Gruntów i Budynków skrót EGiB powinny być takie same jak w „Księgach Wieczystych”  znajdujących się w sądach gdy nie są takie same geodeta wykony je wykaz synchronizacyjny)</li>
        </ul>
      </div>
      <div class="col-12">
        <h2>Nasza oferta:</h2>
        <ul>
          <li>Geodezyjna obsługa inwestycji
            <ul>
              <li>Tyczenie domów jednorodzinnych</li>
              <li>Tyczenie sieci uzbrojenia terenu</li>
            </ul>

          </li>
          <li>Pomiary sytuacyjno – wysokościowe
            <ul>
              <li>Mapy do celów projektowych</li>
              <li>Inwentaryzacja powykonawcza budynków, przyłączy oraz sieci uzbrojenia terenu</li>
              <li>Pomiary wysokości obiektów niedostępnych</li>
              <li>Pomiary uzupełniające</li>
              <li>Prace geodezyjno – prawne</li>
              <li>Mapy do celów prawnych</li>
              <li>Badanie stanu prawnego nieruchomości</li>
              <li>Podziały nieruchomości</li>
              <li>Sporządzanie dokumentacji do celów prawnych</li>
              <li>Wznowienia i wyznaczenia znaków i granic nieruchomości</li>
            </ul>
          </li>
        </ul>

        <h2>JEŻELI</h2>
        <ul>
          <li>chcesz wykonać mapę do celów projektowych</li>
          <li>szukasz zespołu geodezyjnego do obsługi realizowanych przez Ciebie inwestycji</li>
          <li>chcesz nanieść nowe elementy na mapę</li>
          <li>kupiłeś działkę, potrzebujesz wyznaczyć ją w terenie i poznać jej powierzchnię</li>
          <li>masz już nieruchomość i chcesz przeprowadzić jej podział</li>
          <li>sąsiad nie jest przekonany co do przebiegu waszej wspólnej granicy</li>
          <li>chcesz sprawdzić powierzchnię lokalu mieszkalnego, usługowego przed zakupem lub wynajęciem</li>
          <li>budujesz (lub dopiero projektujesz) dom lub inne obiekty budowlane, a Twój projektant potrzebuje aktualnej mapy</li>
          <li>planujesz tyczenie własnego domu</li>
          <li>chcesz wytyczyć i zinwentaryzować przyłącze gazu, prądu, wody, CO lub kanalizacji, oraz określić na jakiej wysokości</li>
          <li>nad Twoją działką przebiegają przewody linii energetycznych</li>
        </ul>
        <p>Świetnie trafiłeś!
          Nasz profesjonalny i doświadczony zespół  jest do Twojej dyspozycji! Zakres naszej działalności obejmuje: Wznowienie znaków granicznych, inwentaryzacja budynków i przyłączy, tworzenie map do celów projektowych, podział działki, wytyczenie budynków oraz sieci i uzbrojenia terenu.
          Dodatkowo chałbym się zajmować wykonywaniem:</p>
          <ul>
            <li>ortofotomapa</li>
            <li>NMT (Numeryczny Model Terenu);</li>
            <li>Modele 3D;</li>
            <li>Chmura punktów;</li>
            <li>Pomiar objętości.</li>
          </ul>
      </div>
    </div>
  </div>
</div>
