import { Component } from '@angular/core';
import { Location } from "@angular/common";
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { NavigationItem } from '../../models/navigation-item';
import { HostListener } from "@angular/core";
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent {
  @HostListener("window:scroll", [])
  onWindowScroll() {
    const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.show = offset > 64;
  }

  show: Boolean = false;
  transparent: Boolean = true;

  items: NavigationItem[] = [
    {label: 'Strona Główna', anchor: 'welcome', icon: ''},
    {label: 'Oferta', anchor: 'offer', icon: ''},
    {label: 'Kontakt', anchor: 'contact', icon: ''},
  ];

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  constructor(private breakpointObserver: BreakpointObserver, private router: Router, private location: Location) {
    router.events.subscribe(val => {
      this.transparent = location.path() === "";
    });
  }

  scrollTo(anchor: string): void {
    console.log(anchor, document.getElementById(anchor));
    document.getElementById(anchor)?.scrollIntoView({ behavior: 'smooth' });
  }

}
