export const ParticlesConfig = {
    particles: {
      number: {
        value: 40
        // density: {
        //   enable: true,
        //   value_area: 1200
        // }
      },
      color: {
        value: '#FFFFFF'
      },
      shape: {
        type: 'circle',
        stroke: {
          width: 1,
          color: '#FFFFFF0F'
        }
      },
      opacity: {
        value: 1,
        random: true,
        anim: {
          enable: true,
          speed: 0.1,
          opacity_min: 0.25,
          sync: true
        }
      },
      size: {
        value: 2,
        random: true,

      },
      line_linked: {
        enable: true,
        distance: 150,
        color: '#FFFFFF',
        opacity: 1,
        width: 1
      },
      move: {
        enable: true,
        speed: 1,
        direction: 'none',
        random: true,
        straight: false,
        out_mode: 'flase',
        bounce: false,
        attract: {
          enable: true,
          rotateX: 2000,
          rotateY: 2000
        }
      }
    },
    retina_detect: true
  };
