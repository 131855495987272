<div id="home" class="d-flex align-items-center content">
  <div class="container">
    <p class="headline"><b>Usługi</b> Geodezyjne !</p>
    <p class="subline">Pisanie o sobie nie jest naszą mocną stroną wolimy działać niż rzucać pustymi hasłami. Jesteśmy zgranym zespołem profesjonalistów, i ludzi z pasją, którzy słuchając klientów spełniają ich oczekiwanie.</p>
    <div class="row">
      <div class="col-12 col-sm-4" *ngFor="let item of items">
        <div class="card">
          <div class="card-image">
            <img [src]="'assets/img/' + item.image" [alt]="item.title" width="100%" />
          </div>
          <h2 class="card-title" [innerHTML]="item.title"></h2>

          <div class="card-description">
            <div *ngFor="let text of item.content" class="card-description-row">
              <span class="material-icons">keyboard_double_arrow_right</span>
              <span>{{ text }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="subline">Chcesz aby twoja inwestycja została wykonana sprawnie oraz z najwyższą starannością? Trafiłeś idealnie, zadzwoń do nas już teraz i sprawdź jak szybko możemy zrealizować twoje zlecenie.</p>
  </div>
</div>
