import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  items: ExperienceListItem[] = [
    {
      image: 'concept.png',
      title: '<b>Pomiary</b> sytuacyjno – wysokościowe',
      content: [
        'Sporządzanie map do celów projektowych',
        'Tyczenie budynków, sieci uzbrojenia terenu i innych budowli',
        'Inwentaryzacja powykonawcza obiektów budowlanych',
        'Kompleksowa obsługa inwestycji'
      ]
    },
    {
      title: '<b>Geodezyjne</b> Prace Prawne',
      image: 'paragraph.png',
      content: [
        'Podziały nieruchomości',
        'Ustalenie przebiegu granic',
        'Wznowienie znaków granicznych',
        'Dokumentacja do celów prawnych'
      ]
    },
    {
      image: 'drone.png',
      title: '<b>Fotogrametria</b>',
      content: [
        'Opracowanie ortofotomapy na podstawie zdjęć lotniczych',
        'Pomiary objętości mas ziemnych metodą fotogrametryczną',
        'Sporządzenie numerycznego modelu terenu',
        'Monitoring postępu prac budowlanych',
        'Pomiary terenów trudno dostępnych i niebezpiecznych'
      ]
    }
  ];

  constructor() { }

  ngOnInit(): void { }
}

export interface ExperienceListItem {
  image: string;
  title: string;
  content?: string[];
}
