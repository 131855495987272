<div class="content">
    <div class="container align-items-start">

      <p class="headline">KONTAKT</p>


      <div class="row">
        <div class="col-12">
          <p *ngFor="let detail of contactDetails" class="contact-details">
            <span class="material-icons">{{detail.icon}}</span>
            <span>{{detail.text}}</span>
          </p>
        </div>


        <div class="col-12">
          <form class="row" [formGroup]="form" class="row" (ngSubmit)="onSubmit()">
            <div class="col-12 col-sm-6">
              <div class="row">

                <mat-form-field appearance="outline" class="col-6">
                  <mat-label>Imię</mat-label>
                  <input matInput placeholder="Jan" formControlName="firstname">
                  <!-- <mat-icon matSuffix>sentiment_very_satisfied</mat-icon> -->
                  <!-- <mat-hint>Hint</mat-hint> -->
                </mat-form-field>
                <mat-form-field appearance="outline" class="col-6">
                  <mat-label>Nazwisko</mat-label>
                  <input matInput placeholder="Kowalski" formControlName="lastname">
                </mat-form-field>



                <!-- TELEFON -->
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>Telefon</mat-label>
                  <input matInput placeholder="+48 102 171 981" formControlName="phone">
                </mat-form-field>

                <!-- EMAIL -->
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>Email</mat-label>
                  <input matInput placeholder="jan.kowalski@gmail.com" type="email" formControlName="email" required>
                </mat-form-field>

                <!-- MIEJSCOWOŚĆ -->
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>Miejscowość</mat-label>
                  <input matInput placeholder="Gorlice" formControlName="city">
                </mat-form-field>

                <!-- NUMER DZIAŁKI EWIDENCYJNEJ -->
                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>Numer działki ewidencyjnej</mat-label>
                  <input matInput placeholder="837192942" formControlName="parcel">
                </mat-form-field>
              </div>
            </div>
            <div class="col-12 col-sm-6">
              <div class="row">

                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>Rodzaj pracy geodezyjnej</mat-label>
                  <input matInput placeholder="Wycena nieruchomości"  formControlName="workType">
                </mat-form-field>

                <mat-form-field appearance="outline" class="col-12">
                  <mat-label>Dodatkowe informacje</mat-label>
                  <textarea matInput formControlName="additionalInformations" rows="17"></textarea>
                </mat-form-field>
              </div>
            </div>

            <div class="col-11">
              <mat-checkbox formControlName="agreement" required></mat-checkbox>
              <div class="agreement">
                Zapoznałem się z <a href="assets/documents/polityka_prywatności.pdf">"Polityką prywatności"</a>
              </div>

            </div>
            <div class="col-1 align-right">
              <button mat-raised-button color="primary" type="submit">
                Wyślij
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
