import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MailSenderService } from 'src/app/services/mail-sender.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactDetails: {icon: string, text: string}[] = [
    {icon: 'person', text: 'Guzik Mateusz'},
    {icon: 'verified', text: 'NIP: 738-216-28-91'},
    {icon: 'phone', text: '503 501 370'},
    {icon: 'email', text: 'guzikgeodezja@gmail.com'}
  ];

  form: FormGroup = new FormGroup({
    firstname: new FormControl(''),
    lastname: new FormControl(''),
    phone: new FormControl(''),
    email: new FormControl('', [
      Validators.required,
      Validators.email
    ]),
    city: new FormControl(''),
    parcel: new FormControl(''),
    workType: new FormControl(''),
    additionalInformations: new FormControl(''),
    agreement:  new FormControl(true, [
      Validators.requiredTrue
    ]),
  });

  constructor(private mailSender: MailSenderService, public snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    if (this.form.valid) {
      const name = this.form.value.firstname + ' ' + this.form.value.lastname;

      const request = this.mailSender.send(
        this.form.value.email,
        name,
        this.form.value.phone,
        this.form.value.city,
        this.form.value.parcel,
        this.form.value.workType,
        this.form.value.additionalInformations
      ).toPromise();

      request.then((success) => {
        this.snackBar.open('Wiadomość została wysłana', '', {
          duration: 5000
        });

      }).catch((error) => {
        this.snackBar.open('Nie można było wysłać wiadomości!', '', {
          duration: 5000
        });
      });

    } else {
      this.snackBar.open('Wypełnij formularz', '', {
        duration: 5000
      });
    }

  }

}
