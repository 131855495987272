
<div class="container" id="offer">
  <div class="row">
    <div class="col-12 col-sm-6">
      <div class="row description">
        <p class="supline align-self-start">DLA KLIENTA</p>
        <p class="headline align-self-start"><b>Zapewniamy wsparcie<br /> wielu branż</b></p>
        <p class="subline">Poprzez zdobyte doświadczenie podczas realizacji wielu inwestycji, nawiązaliśmy współpracę z różnymi branżami. Korzystając z naszych usług możemy pomóc Pańswtu przejść przez cały proces inwestycyjny od A do Z.</p>

        <p>Nasza pomoc obejmuje:</p>

        <div class="description-list">
          <div *ngFor="let text of list" class="description-list-item">
            <span class="material-icons">keyboard_double_arrow_right</span>
            <span>{{ text }}</span>
          </div>
        </div>

      </div>
    </div>
    <div class="col-12 col-sm-6">
      <div class="row">
        <div class="col-8 offset-2">
          <img src="assets/img/handshake.png" width="100%" alt="">
        </div>
      </div>
    </div>
  </div>
</div>

