import { Component } from '@angular/core';



@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss']
})
export class OfferComponent {
  list: string[] = [
    'wykonastwo budowlane oraz nadzór budowlany',
    'badanie geologiczne',
    'wycena nieruchomości',
    'kancelarie notarialne',
    'agencje nieruchomości (kupno/sprzedaż)',
    'doradztwo finansowe',
    'ubezpieczenia'
  ];
  constructor() { }
}
