<mat-toolbar color="primary" class="mat-elevation-z6" id="contact">
  <mat-toolbar-row class="links" class="mat-elevation-z6">
    <div class="container">
      <div class="row flexbox">
        <span class="col-12 col-sm-auto text-center">&copy; {{ year }} Guzik Geodezja | Mateusz Guzik</span>
        <span class="spacer"></span>
        <span class="col-12 col-sm-auto text-center">Created by:&nbsp; <a href="https://www.linkedin.com/in/ziobrowsky/" target="_blank">Mateusz Ziobrowski</a></span>
        <span class="col-12 col-sm-auto text-center">Designed by: Paulina Nowak | <a class="non-link">paulinaaanowaak@gmail.com</a></span>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
