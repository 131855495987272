import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './modules/material.module';
import { NavigationComponent } from './components/navigation/navigation.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { HomeComponent } from './views/home/home.component';
import { OfferComponent } from './views/offer/offer.component';
import { FooterComponent } from './components/footer/footer.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule,  ScreenTrackingService } from '@angular/fire/analytics';
import { WelcomeComponent } from './views/welcome/welcome.component';
import { HttpClientModule } from '@angular/common/http';
import { ExperienceComponent } from './views/experience/experience.component';
import { IntroductionComponent } from './views/introduction/introduction.component';
import { ContactComponent } from './views/contact/contact.component';
import { MainComponent } from './views/main/main.component';
import { PhotoGalleryComponent } from './views/photo-gallery/photo-gallery.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';


const firebaseConfig = {
  apiKey: 'AIzaSyCZnCPZim99nJvi7Yu7mnHuSevwwbNORR8',
  authDomain: 'guzik-geodezja.firebaseapp.com',
  projectId: 'guzik-geodezja',
  storageBucket: 'guzik-geodezja.appspot.com',
  messagingSenderId: '1084497713814',
  appId: '1:1084497713814:web:d5a9961717a60572dd40b7',
  measurementId: 'G-ZKC0PXVSC2'
};

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    HomeComponent,
    OfferComponent,
    FooterComponent,
    WelcomeComponent,
    ExperienceComponent,
    IntroductionComponent,
    ContactComponent,
    MainComponent,
    PhotoGalleryComponent,
    CarouselComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatDialogModule,
    ScrollingModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAnalyticsModule,
    MatSnackBarModule
  ],
  providers: [
    ScreenTrackingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
