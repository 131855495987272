
<div class="parallax" id="welcome">
  <div id="particles-js"></div>
  <div class="background">
    <div class="container">
      <div class="row justify-content-center">
        <div class="banner col-10"></div>
      </div>
    </div>
  </div>

  <div class="anchor-icon align-center" (click)="scrollToHome()">
    <span class="material-icons anchor-icon">
      expand_more
    </span>
  </div>
</div>
