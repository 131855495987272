<div class="introduction d-flex align-items-center">
    <div class="container">
        <div class="row">
            <div class="headline col-12">
                <i class="material-icons">stacked_line_chart</i>
            </div>
            <div class="col-12">
                <h2>Pisanie o nas nie jest naszą mocną stroną, wolimy działać niż rzucać pustymi hasłami.</h2>
                <h2>Naszą misją jest stworzenie unikatowej relacji między nami a Tobą , otoczymy Cię opieką już od pierwszego momentu naszej współpracy.</h2>
                <h2>Naszym priorytetem jest satysfakcja klientów oraz stałe doskonalenie, wierzymy , że tylko dzięki nim możemy być konkurencyjni na rynku. </h2>
                <h2>Jesteśmy zgranym zespołem profesjonalistów i ludzi z pasją, którzy słuchając klientów spełniają ich oczekiwania</h2>
                <h2>Atutem naszej oferty jest różnorodność.</h2>
                <h2>Dzięki współpracy z rzetelnej agentami i kontrahentami, umacniamy naszą pozycje na rynku.</h2>
            </div>
        </div>
    </div>
</div>
