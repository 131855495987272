import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MailSenderService {

  constructor(private http: HttpClient) { }

  send(
    email: string,
    name: string = 'Brak',
    phone: string = 'Brak',
    city: string = 'Brak',
    parcel: string = 'Brak',
    workType: string = 'Brak',
    additionalInformations: string = 'Brak'
    ): Observable<any> {

    const options: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const json = {
      email,
      name,
      phone,
      city,
      parcel,
      workType,
      additionalInformations
    };

    return this.http.post(environment.api, json, { headers: options });
  }
}
