<div class="d-flex align-items-center content">
    <div class="container">
      <p class="headline"><b>Zrealizowane</b> zadania</p>

      <div class="row">
          <div *ngFor="let img of album; let i=index" class="col-6 col-sm-3 image-frame">
              <div class="fillout" [ngStyle]="{'background-image': 'url(' + img.thumb+ ')'}" (click)="open(i)"></div>
          </div>
      </div>
    </div>
</div>


